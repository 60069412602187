<template>
  <div class="content-manage">
    <div class="btn-box">
      <span>关于集采配置</span>
      <fontButton @click="save">保存</fontButton>
    </div>
    <div class="edit-box" id="edit-box"></div>
  </div>
</template>
<script>
import E from 'wangeditor' 
import {ajax_post_xuzhi, ajax_get_xuzhi, fileUpload} from  "../../../api/index"
export default {
  name: 'contentManageAbout',
  data () {
    return {
      editor: null,
      editData:''
    }
  },
  created(){
    
  },
  mounted(){
    this.getData()
  },
  methods:{
    getData(){
      ajax_get_xuzhi({configName:"aboutIn"}).then(res=>{
        if(res.code == 200){
          this.editData = res.result.configValue;
          this.editInit();
        }
      }).catch(err=>{

      })
    },
    editInit(){
      this.editor = new E('#edit-box')
      this.editor.config.uploadImgServer = '/api/upload/imageUpload'
      this.editor.config.customUploadImg = this.uploadImg;
      // this.editor.config.colors = [
      //     '#000000',
      //     '#eeece0',
      //     '#1c487f',
      //      '#4d80bf'
      // ]
      this.editor.create()
      this.editor.txt.html(this.editData)
    },
    uploadImg(resultFiles, insertImgFn){
      const serverUrl =  "https://wrjc.wanrongly.com/"
      console.log(resultFiles,"resultFiles")
      let data = new FormData();
      data.append('file',resultFiles[0])
      fileUpload(data).then(res=>{
        if(res.code == 200){
          let imgUrl = [serverUrl + res.result]
          console.log(imgUrl)
          insertImgFn(imgUrl)
        }
      }).catch(err=>{
        console.log("ajax_err,",err)
      })
         // resultFiles 是 input 中选中的文件列表
         // insertImgFn 是获取图片 url 后，插入到编辑器的方法

         // 上传图片，返回结果，将图片插入到编辑器中
        //  insertImgFn(imgUrl)
    },
    save(){
      let html = this.editor.txt.html();
      console.log(html,"html")
      ajax_post_xuzhi({configName:"aboutIn",configValue:html}).then(res=>{
        if(res.code == 200){
          this.$message.success("操作成功！")
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.content-manage {
  width: 100%;
  height: 100%;
  .btn-box{
    height: 40px;
    width: 100%;
    text-align: right;
    display:flex;
    justify-content:space-between;
    align-items: center;
  }
  .edit-box{
    width: 100%;
    height: calc(100% - 40px);
  }
}
</style>
